import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import Header02 from "./header/Header02"
import Header03 from "./header/Header03";
import Header04 from "./header/Header04";

export default function Layout({ children }) {  let header = <Header02 />;

  return (
    <>
      {header}
      <Wallet_modal />
      <BidsModal />
      <BuyModal />
      <main>{children}</main>
      <Footer />
    </>
  );
}
