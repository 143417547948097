const footerMenuList = [
  // {
  //   id: 1,
  //   title: "Marketplace",
  //   diffClass: "md:col-start-7",
  //   list: [
  //     {
  //       id: 1,
  //       href: "#",
  //       text: "All NFTs",
  //     },
  //     {
  //       id: 2,
  //       href: "#",
  //       text: "art",
  //     },
  //     {
  //       id: 3,
  //       href: "#",
  //       text: "music",
  //     },
  //     {
  //       id: 4,
  //       href: "#",
  //       text: "domain names",
  //     },
  //     {
  //       id: 5,
  //       href: "#",
  //       text: "collections",
  //     },
  //     {
  //       id: 6,
  //       href: "#",
  //       text: "virtual world",
  //     },
  //   ],
  // },
  {
    id: 2,
    title: "Company",
    diffClass: "md:col-start-9",
    list: [
      {
        id: 1,
        href: "https://www.vitruveo.xyz/",
        text: "Website",
      },
      // {
      //   id: 2,
      //   href: "#",
      //   text: "About Us",
      // },
      {
        id: 3,
        href: "mailto:get@poetoken.org",
        text: "Contact Us",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Privacy Policy",
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "Terms and Conditions",
      // },
    ],
  },
  {
    id: 3,
    title: "Account",
    diffClass: "",
    list: [
      // {
      //   id: 1,
      //   href: "/",
      //   text: "Sign In",
      // },
      {
        id: 2,
        href: "/account",
        text: "My Account",
      },
      {
        id: 3,
        href: "/issuer",
        text: "Issuer",
      },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "Create Item",
      // },
    ],
  },
];

const socialIcons = [
  // {
  //   id: 1,
  //   href: "https://www.facebook.com",
  //   text: "facebook",
  // },
  // {
  //   id: 2,
  //   href: "https://www.twitter.com",
  //   text: "twitter",
  // },
  {
    id: 3,
    href: "https://discord.com/invite/vitruveo",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com/vitruveo_chain/",
    text: "instagram",
  },
  // {
  //   id: 5,
  //   href: "https://www.tiktok.com",
  //   text: "tiktok",
  // },
];

export { footerMenuList, socialIcons };
