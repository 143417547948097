import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { AppProvider } from "../components/AppContext";
import { useRef } from "react";
import { ToastContainer } from "react-toastify";
0;
import "react-toastify/dist/ReactToastify.css";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.route;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <body className="dark">
      { !pid.includes('/poet/') && <Meta /> }
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <AppProvider value={store}>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/" ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </AppProvider>
          </MetaMaskProvider>
          <ToastContainer position="bottom-right" theme="light" />
        </ThemeProvider>
      </Provider>
    </body>
  );
}

export default MyApp;
