import Head from "next/head";

const Meta = ({ title, keyword, desc, img, url }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon-32x32.png" />
        <meta name="keyword" content={keyword} />
        <meta name="description" content={desc} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={img} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="poet.vitruveo.xyz" />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={img} />

      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "POET",
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "Create, Issue and Claim your Proof of Engagement Token (POET). Powered by Vitruveo.",
  img: 'https://opengraph.b-cdn.net/production/documents/e6258d8a-12f7-40b0-86c7-895423eed5d5.png?token=66Q0mOYyU96q4KnQNjXfV62oQ2AYeIU7P0lagp8cTG0&height=1080&width=960&expires=33242000278',
  url: 'https://poet.vitruveo.xyz'
};

export default Meta;
