import React, { createContext, useState, useContext, useEffect } from "react";
import jwt_decode from "jwt-decode";

const AppContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);

function AppProvider({ children }) {
  const [userDetails, setUserDetails] = useState({
    email: "",
    passCode: "",
    signedIn: false,
    loading: true,
    roles: [],
  });
  
  let updateState = (state) => {
    setUserDetails({  ...state });
  };

  const getUserInfo = (access_token) =>
    new Promise(async (resolve, reject) => {
      try {
        let url = `${process.env.NEXT_PUBLIC_API_URL}v1/user/profile/info`;
        let body = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        };

        const resp = await fetch(url, body);
        const jsonRes = await resp.json();
        if (jsonRes.success) {
          resolve(jsonRes.data);
        } else {
          reject("");
        }
      } catch (e) {
        reject("");
      }
    });

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      updateState({ loading: false });
      return;
    }
    if (token) {
      updateState({ loading: true });
      var decoded = jwt_decode(token);
      const { exp } = decoded;
      if (exp < (new Date().getTime() + 1) / 1000) {
        updateState({ loading: false });
        return false;
      }
      getUserInfo(token)
        .then((data) => {
          updateState({
            ...userDetails,
            roles: decoded.roles,
            isAdmin: decoded.roles.filter(i => i.name == 'admin')?.length > 0 ? true : false,
            signedIn: true,
            email: data.email_address,
            is_custodial: data.is_custodial,
            id: data.id,
            username: data.username,
            access_token: token,
            loading: false,
          });
        })
        .catch(() => {
          updateState({
            ...userDetails,
            roles: decoded.roles,
            isAdmin: decoded.roles.filter(i => i.name == 'admin')?.length > 0 ? true : false,
            signedIn: true,
            email: decoded.email_address,
            is_custodial: decoded.is_custodial,
            id: decoded.id,
            username: decoded.username,
            access_token: token,
            loading: false,
          });
        });
    }
  }, []);

  return (
    <AppContext.Provider value={userDetails}>
      <AppDispatchContext.Provider value={setUserDetails}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}
const useAppState = () => useContext(AppContext);
const useAppDispatch = () => useContext(AppDispatchContext);
export { AppProvider, useAppState, useAppDispatch };
