import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    email: "",
    passCode: "",
    signedIn: false,
    loading: true,
    roles: [],
}


export const userSlice = createSlice({
    name: "user",
    initialState,
    updateUserState: (state, payload) => {
        return { ...state, ...payload }
    }
})

export default userSlice.reducer;
